import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ITrackerSlice, Itracker, ILocationData } from './trackerSlice.contracts';
import { NoEncryption } from '@mui/icons-material';

const initialState: ITrackerSlice = {
  tracker: {
    _id: { $oid: 'null' },
    imei: 'null',
    lastPing: 'null',
    lat: 'null',
    lon: 'null',
    status: 'null',
    notifications: 'null',
    battery: NaN,
    name: 'null',
    listenerRef: 'null',
    listenerSeq: 'null',
    userName: 'null',
    userSpecs: 'null',
    userAdditional: 'null'
  },

  locationData: [],
  // locationData: [
  //   { lat: '-33.9374610', lon: '18.87769', timestamp: '2023-07-25T12:34:56Z' }, // Default location 1
  //   { lat: '-33.9374624', lon: '18.87787', timestamp: '2023-07-25T12:45:00Z' }, // Default location 2
  //   { lat: '-33.9374636', lon: '18.877', timestamp: '2023-07-25T13:00:00Z' }, // Default location 3
  // ],
  pathTracking: true, 

};
const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setTrackerData(state, action: PayloadAction<{ tracker: Itracker }>) {
      const { tracker } = action.payload;
      state.tracker = tracker;
    },
    updateLocationData(state, action: PayloadAction<ILocationData[]>) {
      state.locationData = action.payload;
    },
    setPathTracking(state, action: PayloadAction<boolean>) {
      state.pathTracking = action.payload;
    },
  },
});

export const { setTrackerData, updateLocationData, setPathTracking} = trackerSlice.actions;
export default trackerSlice.reducer;

export const selectTracker = (state: RootState) => state.tracker.tracker;
export const selectLocationData = (state: RootState) => state.tracker.locationData;
export const selectPathTracking = (state: RootState) => state.tracker.pathTracking;